import {createContext, useContext} from 'react';
import {DashboardMeta} from "shared";
// import { DashboardMeta } from '../../../../shared';
// import {DashboardMeta} from "@shared/interfaces";

interface AppContextType {
    projectName: string | null;
    setProjectName: (name: string | null) => void;

    dashboardMeta: DashboardMeta | null;
    setDashboardMeta: (meta: DashboardMeta) => void;

    // Add more global state here as needed, e.g., user info
    // userName?: string | null;
    // setUserName?: (name: string) => void;
}

export const AppContext = createContext<AppContextType>({
    projectName: null,
    setProjectName: () => {
    },
    // Default values for future fields
    dashboardMeta: null,
    setDashboardMeta: () => {
    }
});

export const useAppContext = () => useContext(AppContext);
