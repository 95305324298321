import React, { useEffect, useState } from 'react';
import {
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Tooltip,
    Typography
} from '@mui/material';
import Link from "./Link";
import {apiRequest} from "../utils/api";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface ProjectEntry {
    id: number;
    name: string;
    fte: number;
    ftusers: number;
    devDays: number;
    users: string[];
    type: string;
}

export interface TopProjectsWidgetData {
    projects: ProjectEntry[];
}

const TopProjectsWidget: React.FC = () => {
    const [data, setData] = useState<TopProjectsWidgetData | null>(null);

    useEffect(() => {
        apiRequest<TopProjectsWidgetData>('getTopProjectsWidgetData', {})
            .then((response) => setData(response.data));
    }, []);

    useEffect(() => {
        if (data) {
            console.log("Have data", data);
        }
    }, [data]);

    if (!data) {
        return <div>Loading...</div>;
    }

    const softwareDevelopmentProjects = data.projects.filter(project => project.type === "Software Development");
    const otherProjects = data.projects.filter(project => project.type !== "Software Development");

    return (
        <div className="widget-container">
            {/*<Stack alignItems="center" direction="row" gap={1}>*/}
            {/*    <Typography variant="subtitle1" component="h2" gutterBottom sx={{ color: 'text.secondary', fontWeight: 'bold', marginBottom: '0.5rem' }}>*/}
            {/*        Dev Projects*/}
            {/*    </Typography>*/}
            {/*    <Tooltip title="Delete"><InfoOutlinedIcon sx={{ color: 'text.secondary', fontWeight: 'bold', marginBottom: '0.5rem' }}/></Tooltip>*/}
            {/*</Stack>*/}

            <Typography variant="subtitle1" component="h2" gutterBottom sx={{ color: 'text.secondary', fontWeight: 'bold', marginBottom: '0.5rem' }}>
                Dev Projects
            </Typography>

            {softwareDevelopmentProjects.length > 0 ? (
                <TableContainer component={Paper}>
                    <Table size="small" aria-label="software development projects">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ fontWeight: 'bold' }}>Name</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }} align="right">FTE</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {softwareDevelopmentProjects.map((project) => (
                                <TableRow key={`software-${project.id}`}>
                                    <TableCell component="th" scope="row">
                                        <Link to={`/project/${project.id}`}>{project.name}</Link>
                                    </TableCell>
                                    <TableCell align="right">
                                        <div title={project.users.join(', ')} style={{textDecoration: 'underline'}}>
                                            {project.fte.toFixed(1)}
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Typography>No software development projects found.</Typography>
            )}

            {/* Other Projects */}
            <Typography variant="subtitle1" component="h2" gutterBottom sx={{ color: 'text.secondary', fontWeight: 'bold', marginBottom: '0.5rem', marginTop: '2rem' }}>
                Software-type projects
            </Typography>

            {/*<Typography variant="h6" gutterBottom style={{ marginTop: '2rem' }}>Other Projects</Typography>*/}
            {otherProjects.length > 0 ? (
                <TableContainer component={Paper}>
                    <Table size="small" aria-label="other projects">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ fontWeight: 'bold' }}>Name</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }} align="right">FTE</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {otherProjects.map((project) => (
                                <TableRow key={`other-${project.id}`}>
                                    <TableCell component="th" scope="row">
                                        <Link to={`/project/${project.id}`}>{project.name}</Link>
                                    </TableCell>
                                    <TableCell align="right">
                                        <div title={project.users.join(', ')} style={{textDecoration: 'underline'}}>
                                            {project.fte.toFixed(1)}
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Typography>No other projects found.</Typography>
            )}
        </div>
    );
};

export default TopProjectsWidget;
