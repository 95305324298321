import React, {useEffect, useState} from 'react';
import './../pages/DashboardPage.css';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from '@mui/material';
import {apiRequest} from "../utils/api";

interface TeamWidgetProps {
    scope: string;
}

interface Assignee {
    id: string;
    name: string;
    involvement: number;
    engagement: string;
}

export interface TeamWidgetData {
    people: Assignee[];
}

const TeamWidget: React.FC<TeamWidgetProps> = ({scope}) => {
    const [data, setData] = useState<TeamWidgetData | null>(null);

    useEffect(() => {
        apiRequest<TeamWidgetData>('getTeamWidgetData', {scope})
            .then((response) => setData(response.data))
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }, [scope]);

    useEffect(() => {
        if (data) {
            console.log("Have data", data);
        }
    }, [data]);

    return (
        <div className="widget-container">
            {/*<Typography variant="subtitle1" component="h2" gutterBottom sx={{ color: 'text.secondary', fontWeight: 'bold', textAlign: 'center' }}>*/}
            <Typography variant="subtitle1" component="h2" gutterBottom sx={{ color: 'text.secondary', fontWeight: 'bold', marginBottom: '1rem' }}>
            {/*<Typography variant="h6" component="h2" gutterBottom sx={{ color: 'text.secondary', marginBottom: '1rem' }}>*/}
                Team
            </Typography>
            {data ? (
                <TableContainer component={Paper}>
                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                {/*<TableCell>Name</TableCell>*/}
                                {/*<TableCell align="right">Engagement</TableCell>*/}
                                <TableCell sx={{fontWeight: 'bold'}}>Name</TableCell>
                                <TableCell sx={{fontWeight: 'bold'}} align="right">Engagement</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.people.map((assignee) => (
                                <TableRow
                                    key={assignee.id}
                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                >
                                    <TableCell component="th" scope="row">
                                        {assignee.name}
                                    </TableCell>
                                    <TableCell
                                        align="right">{assignee.engagement}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <div>Loading...</div>
            )}
        </div>
    );
};

export default TeamWidget;
