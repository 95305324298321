import React, {useContext, useState} from 'react';
import {
    Drawer,
    List,
    ListItemButton,
    ListItemText,
    Collapse,
} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Link from './Link'; // Keep the Link component for navigation
import {AppContext} from '../contexts/AppContext';
import {useCurrentPage} from '../hooks/useCurrentPage';
import {useNavigate} from "react-router-dom";
import {DashboardMeta} from "shared";

interface SidebarDrawerProps {
    isDrawerOpen: boolean;
    toggleDrawer: (open: boolean) => void;
}

export const getDevProjectsSortedByFTE = (dashboardMeta: DashboardMeta | null) => {
    if (!dashboardMeta) return [];
    const projectsArray = Object.values(dashboardMeta.projects);
    return projectsArray
        .filter((project) => project.isDev)
        .sort((a, b) => b.fte - a.fte);
};

const SidebarDrawer: React.FC<SidebarDrawerProps> = ({isDrawerOpen, toggleDrawer}) => {
    const [isProjectsOpen, setProjectsOpen] = useState(true);
    const {page, projectId} = useCurrentPage();
    const {dashboardMeta} = useContext(AppContext);
    const navigate = useNavigate();

    const projects = getDevProjectsSortedByFTE(dashboardMeta);

    const toggleProjects = () => {
        setProjectsOpen(!isProjectsOpen);
    };

    return (
        <Drawer anchor="left" open={isDrawerOpen} onClose={() => toggleDrawer(false)}>
            <List sx={{minWidth: '200px', padding: '1rem'}}>
                <Link to={`/`} style={{textDecoration: 'none', color: 'inherit'}}>
                    <ListItemButton
                        selected={page === 'main-dashboard'}
                        key="main-dashboard"
                    >
                        <ListItemText primary="Main Dashboard"/>
                    </ListItemButton>
                </Link>
                <ListItemButton onClick={toggleProjects} key="projects">
                    <ListItemText primary="Top Projects"/>
                    {isProjectsOpen ? <ExpandLess/> : <ExpandMore/>}
                </ListItemButton>

                <Collapse in={isProjectsOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {projects.map((project) => (
                            <Link to={`/project/${project.id}`} style={{textDecoration: 'none', color: 'inherit'}} key={project.id}>
                                <ListItemButton
                                    sx={{pl: 4}}
                                    selected={page === 'project' && projectId === project.id}
                                >
                                    <ListItemText primary={project.name}/>
                                </ListItemButton>
                            </Link>

                        ))}
                    </List>
                </Collapse>

                <ListItemButton disabled>
                    <ListItemText primary="Teams"/>
                </ListItemButton>
                <ListItemButton disabled>
                    <ListItemText primary="Flowcharts"/>
                </ListItemButton>
                <ListItemButton disabled>
                    <ListItemText primary="Custom Query"/>
                </ListItemButton>
                <ListItemButton disabled>
                    <ListItemText primary="Settings"/>
                </ListItemButton>
            </List>
        </Drawer>
    );
};

export default SidebarDrawer;
