import { useEffect, useRef } from 'react';
import { Chart } from 'chart.js/auto';

export const useChart = (data, options = {}) => {
    const chartRef = useRef(null);

    useEffect(() => {
        if (data && chartRef.current) {
            const ctx = chartRef.current.getContext('2d');
            const formattedData = formatDataForChart(data, options.type); // Pass chart type to formatDataForChart

            if (chartRef.current.chart) {
                chartRef.current.chart.destroy();
            }

            chartRef.current.chart = new Chart(ctx, {
                type: options.type || 'bar', // Default to 'bar', or use selected type
                data: formattedData,
                options: {
                    ...options,
                    responsive: true,
                    maintainAspectRatio: false,

                    plugins: {
                        legend: {
                            position: 'top',
                            onClick: (e, legendItem) => {
                                const index = legendItem.datasetIndex;
                                const ci = e.chart;
                                const meta = ci.getDatasetMeta(index);
                                meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null;
                                ci.update();
                            },
                        },
                        title: {
                            display: false,
                            // text: 'Dev Days Spent per Category',
                        },
                    },

                    scales: options.type !== 'pie' ? { // No scales for pie chart
                        x: {
                            stacked: options.type === 'bar',
                        },
                        y: {
                            stacked: options.type === 'bar',
                        },
                    } : undefined,
                },
            });
        }
    }, [data, options.type]);

    return chartRef;
};

// Helper function to format data based on chart type
const formatDataForChart = (aggregatedData, chartType) => {
    const labels = Object.keys(aggregatedData);
    const categoryTotals = {};

    // Step 1: Calculate the total values for each category across all dates
    labels.forEach(date => {
        Object.entries(aggregatedData[date]).forEach(([category, value]) => {
            if (!categoryTotals[category]) {
                categoryTotals[category] = 0;
            }
            categoryTotals[category] += value;
        });
    });

    // Step 2: Sort categories by their total value in descending order
    const sortedCategories = Object.keys(categoryTotals).sort((a, b) => categoryTotals[b] - categoryTotals[a]);

    // Special case for pie chart: Combine all categories into a single dataset
    if (chartType === 'pie') {
        const data = sortedCategories.map(category => categoryTotals[category]);
        const backgroundColors = sortedCategories.map(category => getColorForLabel(category));

        return {
            labels: sortedCategories,
            datasets: [{
                data,
                backgroundColor: backgroundColors,
                hoverOffset: 4,
            }],
        };
    }

    // Step 3: Create datasets in the sorted order
    const datasets = sortedCategories.map((category, index) => {
        const color = getColorForLabel(category);

        // Different formatting for line and bar charts
        if (chartType === 'line') {
            return {
                label: category,
                data: labels.map(date => aggregatedData[date][category] || 0),
                borderColor: color, // Line color
                backgroundColor: color, // Light transparent color for dots (33 is hexadecimal for 20% opacity)
                // backgroundColor: color + '33', // Light transparent color for dots (33 is hexadecimal for 20% opacity)
                fill: false,
                tension: 0.1, // Smoothness of the line
                pointBackgroundColor: color, // Dot color
                pointBorderColor: color, // Border color of dots
                pointRadius: 3, // Dot size
                borderWidth: 2, // Adjust this value to make lines thinner or thicker
            };
        }

        // For bar charts, we return the original dataset format
        return {
            label: category,
            data: labels.map(date => aggregatedData[date][category] || 0),
            backgroundColor: color, // Solid color for bars
        };
    });

    return {
        labels,
        datasets,
    };
};

const predefinedColorMap = {
    "Story": "#3CBA4C",
    "Task": "#00AEE4",
    "Bug": "#F93542",
    "Epic": "#994FDC",
    "To Do": "#CFD4DB",
    "In Progress": "#C7E0FD",
    "Done": "#AAF3DC",
    "No Value": "#B8BBBE",
    "Not Set": "#B8BBBE",
    "Other": "#8A8D90",
    // "No Value": "#A5A5A5",
    // "Other": "#C0C0C0",
};

const colorPalette = [
    // Blue family
    "#8BC1F7", "#519DE9", "#06C", "#004B95",
    // Green family
    "#BDE2B9", "#7CC674", "#4CB140", "#38812F",
    // Cyan family
    "#A2D9D9", "#73C5C5", "#009596", "#005F60",
    // Purple family
    "#B2B0EA", "#8481DD", "#5752D1", "#3C3D99",
    // Gold family
    "#F9E0A2", "#F6D173", "#F4C145", "#F0AB00",
    // Orange family
    "#F4B678", "#EF9234", "#EC7A08", "#C46100",
    // Red family
    "#C9190B", "#A30000", "#7D1007", "#470000",
    // Black family
    // "#F0F0F0", "#D2D2D2", "#B8BBBE", "#8A8D90",
];

function stringToHash(str) {
    let hash = 5381;  // Start with a large prime number
    const primeMultiplier = 31; // Another prime multiplier

    for (let i = 0; i < str.length; i++) {
        // Combine bit shift, XOR, and prime multiplication
        hash = (hash << 5) + hash ^ str.charCodeAt(i);
        hash = hash * primeMultiplier;
    }
    return hash;
}

function getColorForLabel(label) {
    if (predefinedColorMap[label]) {
        return predefinedColorMap[label];
    } else {
        const hash = stringToHash(label);
        const colorIndex = Math.abs(hash) % colorPalette.length;
        return colorPalette[colorIndex];
    }
}
