import React, {useEffect, useState} from 'react';
import {Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from '@mui/material';
import {apiRequest} from '../utils/api';

interface TopItemsWidgetProps {
    scope: string;
    initialLevel: number;
}

interface ItemEntry {
    id: string;
    name: string;
    devDays: number;
}

interface LevelEntry {
    level: number;
    name: string;
    issueTypes: string[];
    total: number;
    children: number;
}

export interface TopItemsWidgetData {
    topItems: ItemEntry[];
    hierarchyData: Record<string, LevelEntry>;
}


interface WorkBreakdownProps {
    hierarchyData: Record<string, LevelEntry>;
    currentLevel: number;
    onBarClick: (levelId: number) => void;
}

const WorkBreakdown: React.FC<WorkBreakdownProps> = ({hierarchyData, currentLevel, onBarClick}) => {
    return (
        <Box>
            {Object.keys(hierarchyData).reverse().map((levelKey, index) => {
                const levelEntry = hierarchyData[levelKey];
                const capturedPercentage = (levelEntry.children / levelEntry.total) * 100;

                return (
                    <Box
                        key={index}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: '0.2rem',
                            cursor: 'pointer',
                            '&:hover': {
                                opacity: 0.8,
                            },
                            border: levelEntry.level === currentLevel ? '1px solid rgba(0,0,0,0.12)' : 'none',
                        }}
                        onClick={() => onBarClick(levelEntry.level)}
                        title={capturedPercentage.toFixed(2) + "% of work captured within " + levelEntry.issueTypes.join(', ')}
                    >
                        <Box
                            sx={{
                                padding: '2px',
                                width: `${capturedPercentage.toFixed(2)}%`,
                                backgroundColor: '#D6EBFD',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: levelEntry.level === currentLevel ? 'bold' : 'normal',
                                    minWidth: '100px',
                                    fontSize: '0.875rem',
                                }}
                            >
                                {levelEntry.name}
                            </Typography>
                        </Box>
                    </Box>
                );
            })}
        </Box>
    );
};

const TopItemsWidget: React.FC<TopItemsWidgetProps> = ({scope, initialLevel}) => {
    const [data, setData] = useState<TopItemsWidgetData | null>(null);
    const [level, setLevel] = useState<number>(initialLevel);
    // const [hierarchyData, setHierarchyData] = useState<LevelEntry[] | null>(null);

    useEffect(() => {
        apiRequest<TopItemsWidgetData>('getTopItemsWidgetData', {scope, level})
            .then((response) => {
                if (response.data && !response.data.hierarchyData[level] && level > 0) {
                    setLevel(level - 1);
                } else {
                    setData(response.data);
                }
                // if (!hierarchyData) {
                //     console.log('shd');
                //     setHierarchyData(response.data.hierarchyData.reverse());
                // }
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, [scope, level]);

    const handleBarClick = (levelId: number) => {
        console.log(`Bar clicked for level: ${levelId}`);
        setLevel(levelId);
        // Handle the click event (e.g., navigate to another page or show more details)
    };

    if (!data) {
        return <div>Loading...</div>;
    }

    return (
        <div className="widget-container">
            {/*{data ? (*/}
            <>
                <Typography
                    variant="subtitle1"
                    component="h2"
                    gutterBottom
                    sx={{color: 'text.secondary', fontWeight: 'bold', marginBottom: '0.5rem'}}
                >
                    Work Breakdown
                </Typography>

                <WorkBreakdown hierarchyData={data.hierarchyData} currentLevel={level} onBarClick={handleBarClick}/>

                <Typography
                    variant="subtitle1"
                    component="h2"
                    gutterBottom
                    sx={{color: 'text.secondary', fontWeight: 'bold', marginBottom: '0.5rem', marginTop: '2rem'}}
                >
                    {data.hierarchyData[level]?.name}: Top Items
                </Typography>

                <TableContainer component={Paper}>
                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{fontWeight: 'bold'}}>Name</TableCell>
                                <TableCell sx={{fontWeight: 'bold'}} align="right">
                                    Dev hours
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.topItems.map((item) => {
                                const maxLength = 50;
                                const isTruncated = item.name.length > maxLength;
                                const displayName = isTruncated ? `${item.name.slice(0, maxLength - 3)}...` : item.name;

                                return (
                                    <TableRow key={item.id}>
                                        <TableCell component="th" scope="row" title={isTruncated ? item.name : ''}>
                                            {displayName}
                                        </TableCell>
                                        <TableCell align="right">{item.devDays.toFixed(1)}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
            {/*) : (*/}
            {/*    <div>Loading...</div>*/}
            {/*)}*/}
        </div>
    );
};

export default TopItemsWidget;
