import React, {useContext, useEffect, useState} from 'react';
import './DashboardPage.css';
import {AppContext} from '../contexts/AppContext';
import {apiRequest} from "../utils/api";
import {buildWidget} from "../widgets/widgetBuilder";
// import {DashboardData} from "../../../../shared"; // Import context
import {DashboardData} from "shared";


function MainDashboardPage() {
    const [data, setData] = useState<DashboardData | null>(null);

    const {setDashboardMeta} = useContext(AppContext); // Use context

    useEffect(() => {
        apiRequest<DashboardData>('getMainDashboardData', {example: 'my-invoke-variable'}).then((response) => {
                const fetchedData = response.data;
                console.log("fd");
                console.log(response);
                if (fetchedData) {
                    setData(fetchedData);
                    setDashboardMeta(fetchedData.meta); // Set project name in context
                }
            }
        );
    }, []);

    return (
        <div>
            {/*<div className="dashboard-header-container">*/}
            {/*    <h1 className="dashboard-header">Main Dashboard: {!!data || 'Loading...'}</h1>*/}
            {/*</div>*/}
            {data && (
                <div className="dashboard">
                    {data.widgets.map((widget, index) => buildWidget(widget, data.meta, index))}
                </div>
            )}
        </div>
    );
}

export default MainDashboardPage;
