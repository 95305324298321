import axios, { AxiosRequestConfig, Method } from 'axios';
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';

export type FetchParams = {
    [key: string]: any;
};

export interface ApiResponse<T = any> {
    data: T;
    error?: string;
}

export async function apiRequest<T = any>(
    endpoint: string,
    params: FetchParams = {},
    method: Method = 'POST'
): Promise<ApiResponse<T>> {
    if (process.env.REACT_APP_STANDALONE !== 'true') {
        const { invoke } = await import('@forge/bridge');
        try {
            const response = await invoke(endpoint, params);
            return { data: response as T };
        } catch (error) {
            return { data: null as any, error: (error as Error).message };
        }
    } else {
        try {
            // const authToken = localStorage.getItem('_auth');
            // const authHeader = authToken ? `Bearer ${authToken}` : '';

            const axiosConfig: AxiosRequestConfig = {
                url: `/api/${endpoint}`,
                method,
                headers: {
                    'Content-Type': 'application/json',
                    // Authorization: authHeader,
                },
                data: method === 'POST' ? params : undefined,
                params: method === 'GET' ? params : undefined,
            };

            const response = await axios(axiosConfig);
            return { data: response.data as T };
        } catch (error: any) {
            let errorMessage = 'API request failed';
            if (error.response) {
                errorMessage = error.response.data?.message || `API request failed with status ${error.response.status}`;
            } else if (error.message) {
                errorMessage = error.message;
            }
            return { data: null as any, error: errorMessage };
        }
    }
}
