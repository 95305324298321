import React, {useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import './DashboardPage.css';
import {AppContext} from '../contexts/AppContext'; // Import context
import {apiRequest} from "../utils/api";
import {buildWidget} from "../widgets/widgetBuilder";
// import {DashboardData} from "../../../../shared";
import {DashboardData} from "shared";

function ProjectDashboardPage() {
    const {projectId} = useParams<{ projectId: string | undefined }>();

    const [data, setData] = useState<DashboardData | null>(null);
    // const {setProjectName} = useContext(AppContext); // Use context
    const {setDashboardMeta} = useContext(AppContext); // Use context


    useEffect(() => {
        // setProjectName(null);
        if (projectId) {
            apiRequest<DashboardData>('getProjectDashboardData', {projectId}).then((response) => {
                const fetchedData = response.data;
                if (fetchedData) {
                    setData(fetchedData);
                    // setProjectName(fetchedData.projectName); // Set project name in context
                    setDashboardMeta(fetchedData.meta); // Set project name in context
                }
            });
        }
    }, [projectId]);

    if (!projectId) {
        return <div>Error: Project ID is missing</div>;
    }

    return (
        <div>
            {data && (
                <div className="dashboard">
                    {data.widgets.map((widget, index) => buildWidget(widget, data.meta, index))}
                </div>
            )}
        </div>
    );
}

export default ProjectDashboardPage;
