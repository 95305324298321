import React, {useEffect, useState, useRef} from 'react';
import {apiRequest} from '../utils/api';
import DateRangeSelector from './DateRangeSelector';
import {useChart} from './useChart';
import './DistributionWidget.css';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import {FormControl, InputLabel, MenuItem, Popper, Select, SxProps, Theme} from "@mui/material";
import {IS_DEMO} from "../reactConstants";
import {DistributionField, SelectOption} from 'shared';
// import {CalculationMethods, DistributionField, SelectOption} from 'shared';
// import {CalculationMethods, DistributionField, SelectOption} from '../../../../src/shared';
// import {DistributionField, SelectOption} from "../../../../src/shared/lib";

export interface DistributionWidgetProps {
    initialScope: string;
    initialLevel?: string;
    initialField: string;
    initialChartType?: string;
    initialRangeType?: string;
    initialRange?: string;
    projects: SelectOption[];
    levels: SelectOption[];
    fields: DistributionField[];
    allFields: DistributionField[];
}

interface ChartData {
    chartData: any; // Replace `any` with the actual type of your chart data
}

function DistributionWidget({
                                initialScope,
                                // initialLevel,
                                initialField,
                                initialChartType,
                                initialRangeType,
                                initialRange,
                                projects,
                                levels,
                                fields,
                                allFields,
                            }: DistributionWidgetProps) {
    const [data, setData] = useState<ChartData | null>(null);
    const [rangeType, setRangeType] = useState<string>(initialRangeType || 'Daily');
    const [rangeValue, setRangeValue] = useState<string>(initialRange || '30d');
    const [selectedProject, setSelectedProject] = useState<string>(initialScope);
    const [selectedLevel, setSelectedLevel] = useState<string>('0');
    const [selectedField, setSelectedField] = useState<string>(initialField);
    const [selectedMetric, setSelectedMetric] = useState<string>('DevHours');
    const [chartType, setChartType] = useState<string>(initialChartType || 'bar'); // New state for chart type

    const metrics = [
        {id: 'DevHours', name: 'Dev hours'},
        // {id: CalculationMethods.DevHours, name: 'Dev hours'},
        {id: 'USD', name: 'USD'},
        {id: 'IssueCount', name: 'Issue count'},
    ];

    const chartTypes = [
        {id: 'bar', name: 'Bar'},
        {id: 'line', name: 'Line'},
        {id: 'pie', name: 'Pie'},
    ];
    // const [metrics] = useState<SelectOption[]>([
    //     {id: 'devhours', name: 'Dev hours'},
    //     {id: 'usd', name: 'USD'},
    //     {id: 'count', name: 'Issue count'},
    // ]);
    //
    // const [chartTypes] = useState<SelectOption[]>([
    //     {id: 'bar', name: 'Bar'},
    //     {id: 'line', name: 'Line'},
    // ]);

    const chartRef = useChart(data ? data.chartData : null, {type: chartType}); // Pass chartType to useChart

    useEffect(() => {
        if (selectedProject && selectedLevel && selectedField && selectedMetric) {
            apiRequest<ChartData>('getDistributionWidgetData', {
                scope: selectedProject,
                customField: selectedField,
                rangeType,
                rangeValue,
                level: selectedLevel,
                method: selectedMetric,
            }).then((response) => setData(response.data));
        }
    }, [selectedProject, selectedLevel, selectedField, selectedMetric, rangeType, rangeValue]);

    const handleRangeChange = (newRangeType: string, newRangeValue: string) => {
        setRangeType(newRangeType);
        setRangeValue(newRangeValue);
    };

    // const allFields: SelectOption[] = [
    // {id: 'kek', name: 'kek'},
    // ...fields[selectedLevel]
    // ]

    return (
        <div style={{height: '400px', display: 'flex', flexDirection: 'column', paddingTop: '8px'}} className="">
            <div className="widget-config">
                <div className="config-left">
                    <SearchSelect
                        sx={{width: 200}}
                        label="Project"
                        options={projects}
                        defaultValue={projects.find(option => {
                            return option.id === selectedProject
                        })}
                        handleChange={(e, value) => value && setSelectedProject(value.id)}
                    />
                    {!IS_DEMO && (
                        <FormControl>
                            <InputLabel sx={{fontSize: '0.875rem'}}>Level</InputLabel>
                            <Select
                                value={selectedLevel}
                                label="Level"
                                size="small"
                                onChange={(e) => setSelectedLevel(e.target.value)}
                                variant='outlined'
                                sx={{
                                    fontSize: '0.875rem', // Smaller font size for the text
                                }}
                            >
                                {levels.map((level) => (
                                    <MenuItem value={level.id} sx={{fontSize: '0.875rem'}} key={level.id}>
                                        {level.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                    <SearchSelect
                        sx={{width: 240}}
                        label="Field"
                        options={allFields}
                        defaultValue={fields.find(option => {
                            return option.id === selectedField
                        })}
                        handleChange={(e, value) => {
                            if (value) {
                                setSelectedField(value.id);
                                setSelectedLevel(String(value.level));
                            }
                        }}
                        suggestedOptions={fields}
                    />
                    <FormControl>
                        <InputLabel sx={{fontSize: '0.875rem'}}>Method</InputLabel>
                        <Select
                            value={selectedMetric}
                            label="Method"
                            size="small"
                            onChange={(e) => setSelectedMetric(e.target.value)}
                            variant='outlined'
                            sx={{
                                fontSize: '0.875rem', // Smaller font size for the text
                            }}
                        >
                            {metrics.map((option) => (
                                <MenuItem value={option.id} sx={{fontSize: '0.875rem'}} key={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl>
                        <InputLabel sx={{fontSize: '0.875rem'}}>Type</InputLabel>
                        <Select
                            value={chartType}
                            label="Type"
                            size="small"
                            onChange={(e) => setChartType(e.target.value)}
                            variant='outlined'
                            sx={{
                                fontSize: '0.875rem', // Smaller font size for the text
                            }}
                        >
                            {chartTypes.map((option) => (
                                <MenuItem value={option.id} sx={{fontSize: '0.875rem'}} key={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div className="config-right">
                    <DateRangeSelector
                        rangeType={rangeType}
                        rangeValue={rangeValue}
                        onRangeChange={handleRangeChange}
                    />
                </div>
            </div>
            <div className="chart-container">
                <canvas ref={chartRef}></canvas>
            </div>
        </div>
    );
}

export default DistributionWidget;

interface SearchSelectProps<OptionType extends SelectOption> {
    label: string;
    options: OptionType[];
    defaultValue?: OptionType;
    handleChange?: (event: React.ChangeEvent<{}>, value: OptionType | null) => void;
    suggestedOptions?: OptionType[];
    sx?: SxProps<Theme>; // Add sx prop to allow custom styling
}

// function SearchSelect({label, options, defaultValue, handleChange, suggestedOptions}: SearchSelectProps) {
function SearchSelect<OptionType extends SelectOption>({
                                                           label,
                                                           options,
                                                           defaultValue,
                                                           handleChange,
                                                           suggestedOptions,
                                                           sx
                                                       }: SearchSelectProps<OptionType>) {
    const [currentOptions, setCurrentOptions] = React.useState<readonly OptionType[]>(suggestedOptions || options);
    const [loading, setLoading] = React.useState(false);
    const [selectedValue, setSelectedValue] = React.useState<OptionType | null>(defaultValue || null);

    const handleInputChange = (event: React.ChangeEvent<{}>, value: string) => {
        if (!suggestedOptions) return;
        if (value === '' || suggestedOptions.some(option => option.name === value)) {
            setCurrentOptions(suggestedOptions);
        } else {
            setCurrentOptions(options);
        }
    };

    const handleChangeInternal = (event: React.ChangeEvent<{}>, newValue: OptionType | null) => {
        setSelectedValue(newValue);
        handleChange && handleChange(event, newValue);
    };

    return (
        <Autocomplete
            // id="asynchronous-demo"
            // sx={{width: 300}}
            sx={{
                // width: 200,
                '.MuiInputBase-input': {
                    fontSize: '0.875rem', // Smaller font size for the input text
                },
                '.MuiAutocomplete-option': {
                    fontSize: '0.875rem', // Smaller font size for the dropdown options
                },
                ...sx
            }}
            size="small"
            PopperComponent={(props) => (
                <Popper {...props} sx={{'.MuiAutocomplete-option': {fontSize: '0.875rem'}}}/>
            )}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option.name}
            defaultValue={defaultValue}
            options={currentOptions}
            loading={loading}
            onInputChange={handleInputChange}
            value={selectedValue}
            onChange={handleChangeInternal}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    sx={{
                        '.MuiInputLabel-root': {
                            fontSize: '0.875rem', // Smaller label font size
                        },
                        '.MuiInputBase-input': {
                            fontSize: '0.875rem', // Smaller input text font size
                        }
                    }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20}/> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}
