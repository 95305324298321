import { useLocation } from 'react-router-dom';

interface CurrentPage {
    page: 'main-dashboard' | 'project' | 'other';
    projectId?: string;
}

export const useCurrentPage = (): CurrentPage => {
    const location = useLocation();
    const pathSegments = location.pathname.split('/').filter(Boolean);

    if (pathSegments.length === 0) {
        return { page: 'main-dashboard' };
    } else if (pathSegments[0] === 'project' && pathSegments[1]) {
        return { page: 'project', projectId: pathSegments[1] }; // Project ID is present
    } else {
        return { page: 'other' };
    }
};
