import React, {Fragment, useEffect, useState} from 'react';
import {BrowserRouter, Router, Route, Routes, useNavigate} from 'react-router-dom';
import MainDashboardPage from './pages/MainDashboardPage';
import ProjectDashboardPage from './pages/ProjectDashboardPage';
import TeamsDashboardPage from './pages/TeamsDashboardPage';
import NavigationBar from "./components/NavigationBar";
import SidebarDrawer from './components/SidebarDrawer'; // Import new Drawer component
import {AppContext} from './contexts/AppContext';
import {IS_STANDALONE} from "./reactConstants";
import AuthModal from "./components/AuthModal";

const ForgeRouter = ({toggleDrawer, isDrawerOpen}) => {
    const [history, setHistory] = useState(null);
    const [historyState, setHistoryState] = useState(null);

    useEffect(async () => {
        const {view} = await import('@forge/bridge');
        view.createHistory().then((newHistory) => {
            setHistory(newHistory);
        });
    }, []);

    useEffect(() => {
        if (history && !historyState) {
            setHistoryState({
                action: history.action,
                location: history.location,
            });
        }
    }, [history, historyState]);

    useEffect(() => {
        if (history) {
            history.listen((location, action) => {
                setHistoryState({
                    action,
                    location,
                });
            });
        }
    }, [history]);

    if (!history || !historyState) return "Loading...";

    return (
        <Router
            navigator={history}
            navigationType={historyState.action}
            location={historyState.location}
        >
            <NavigationBar toggleDrawer={toggleDrawer}/>
            <SidebarDrawer isDrawerOpen={isDrawerOpen} toggleDrawer={toggleDrawer}/>
            <Routes>
                <Route path="/" element={<MainDashboardPage/>}/>
                <Route path="/project/:projectId" element={<ProjectDashboardPage/>}/>
                <Route path="/teams" element={<TeamsDashboardPage/>}/>
            </Routes>
        </Router>
    );
};

const StandaloneRouter = ({toggleDrawer, isDrawerOpen}) => (
    <BrowserRouter>
        <NavigationBar toggleDrawer={toggleDrawer}/>
        <SidebarDrawer isDrawerOpen={isDrawerOpen} toggleDrawer={toggleDrawer}/>
        {/* <AuthModal/> */}
        <Routes>
            <Route path="/" element={<MainDashboardPage/>}/>
            <Route path="/project/:projectId" element={<ProjectDashboardPage/>}/>
            <Route path="/teams" element={<TeamsDashboardPage/>}/>
        </Routes>
    </BrowserRouter>
);

function App() {
    const [projectName, setProjectName] = useState(null);
    const [dashboardMeta, setDashboardMeta] = useState(null);
    const [isDrawerOpen, setDrawerOpen] = useState(false);

    const toggleDrawer = (open) => {
        setDrawerOpen(open);
    };

    return (
        <div className="app-container">
            <AppContext.Provider value={{projectName, setProjectName, dashboardMeta, setDashboardMeta}}>
                {IS_STANDALONE ? (
                    <StandaloneRouter toggleDrawer={toggleDrawer} isDrawerOpen={isDrawerOpen}/>
                ) : (
                    <ForgeRouter toggleDrawer={toggleDrawer} isDrawerOpen={isDrawerOpen}/>
                )}
            </AppContext.Provider>
        </div>
    );
}

export default App;
