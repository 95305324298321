import React from 'react';
import {createRoot} from 'react-dom/client';
// import ReactDOM from 'react-dom/client';
// import ReactDOM from 'react-dom';
// import {Auth0Provider} from '@auth0/auth0-react';
import App from './App';
import createStore from 'react-auth-kit/createStore';
// import {createRoot} from "react-dom/client";
import AuthProvider from 'react-auth-kit';
import createRefresh from 'react-auth-kit/createRefresh';
import axios from "axios";


const store = createStore({
    authName: '_auth',
    authType: 'cookie',
    cookieDomain: window.location.hostname,
    cookieSecure: window.location.protocol === 'https:',
    refresh: createRefresh({
        interval: 10, // The time in sec to refresh the Access token,
        refreshApiCallback: async (param) => {
            try {
                const response = await axios.post("/api/refresh", param, {
                    headers: {'Authorization': `Bearer ${param.authToken}`}
                })
                console.log("Refreshing!!!")
                return {
                    isSuccess: true,
                    newAuthToken: response.data.token,
                    newAuthTokenExpireIn: 10,
                    newRefreshTokenExpiresIn: 60
                }
            } catch (error) {
                console.error(error)
                return {
                    isSuccess: false
                }
            }
        }
    })
});

const root = createRoot(document.getElementById('root'));

root.render(
    // <React.StrictMode>
    <AuthProvider store={store}>
        <App/>
    </AuthProvider>
    // </React.StrictMode>
);


//const root = createRoot(document.getElementById('root'));
//
// root.render(
// <Auth0Provider
//         domain="dev-nepxd8tg1kme1j1a.us.auth0.com"
//         clientId="szOmbUJuWRppnsQtuWbWwC4c6cCYjv0V"
//         authorizationParams={{
//             redirect_uri: window.location.origin
//         }}
//     >
//         <App/>
//     </Auth0Provider>,
// );