import React, {ReactNode} from 'react';
import {useNavigate} from 'react-router-dom';
import {Link as MuiLink} from '@mui/material';
import {IS_STANDALONE} from "../reactConstants";

interface LinkProps {
    to: string;
    children: ReactNode;
    style?: React.CSSProperties;
}

export const handleNavigationClick = async (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    to: string,
    navigate: (path: string) => void
): Promise<void> => {
    console.log("handle click standalone " + IS_STANDALONE);
    if (IS_STANDALONE) return;

    event.preventDefault();

    const appId = 'e2fbbd61-9f69-492d-80bc-85c205044611';
    const environmentId = '24a0bbea-4951-49ad-8228-4ce8acf7621e';
    const prefix = `/jira/apps/${appId}/${environmentId}`;

    if (event.metaKey || event.ctrlKey) {
        const {router} = await import('@forge/bridge');
        router.open(prefix + to);
    } else {
        navigate(to);
    }
};

const Link: React.FC<LinkProps> = ({to, children, style = {}}) => {
    const navigate = useNavigate();

    const handleClick = async (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        await handleNavigationClick(event, to, navigate);
    };

    return (
        // <MuiLink
        //     href={to}
        //     onClick={handleClick}
        //     style={style}
        // >
        //     {children}
        // </MuiLink>
        <a
            href={to}
            onClick={handleClick}
            style={style}
        >
            {children}
        </a>
    );
}

export default Link;