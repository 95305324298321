import React from 'react';
import {FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";

interface DateRangeSelectorProps {
    rangeType: string;
    rangeValue: string;
    onRangeChange: (rangeType: string, rangeValue: string) => void;
}

const DateRangeSelector: React.FC<DateRangeSelectorProps> = ({rangeType, rangeValue, onRangeChange}) => {
    const ranges: { [key: string]: string[] } = {
        // Daily: ['7d', '30d', '60d', '90d'],
        Daily: ['30d', '60d', '90d'],
        // Weekly: ['4w', '8w', '12w', '24w'],
        Weekly: ['8w', '12w', '24w'],
        Monthly: ['3m', '6m', '12m'],
        // Quarterly: ['2q', '3q', '4q']
    };

    // const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const handleChange = (e: SelectChangeEvent<string>) => {
        const [selectedRangeType, selectedRangeValue] = e.target.value.split('-');
        onRangeChange(selectedRangeType, selectedRangeValue);
    };

    return (
        <FormControl>
            <InputLabel sx={{fontSize: '0.875rem'}}>Period</InputLabel>
            <Select
                value={`${rangeType}-${rangeValue}`}
                label="Period"
                size="small"
                onChange={handleChange}
                variant='outlined'
                sx={{
                    fontSize: '0.875rem', // Smaller font size for the text
                }}
            >
                {Object.keys(ranges).map((rangeType) =>
                    ranges[rangeType].map((rangeValue) => (
                        <MenuItem value={`${rangeType}-${rangeValue}`} sx={{fontSize: '0.875rem'}}>
                            {rangeType} ({rangeValue})
                        </MenuItem>
                    ))
                )}

            </Select>
        </FormControl>

        // <select onChange={handleChange} value={`${rangeType}-${rangeValue}`}>
        //     {Object.keys(ranges).map((rangeType) =>
        //         ranges[rangeType].map((rangeValue) => (
        //             <option key={`${rangeType}-${rangeValue}`} value={`${rangeType}-${rangeValue}`}>
        //                 {rangeType} ({rangeValue})
        //             </option>
        //         ))
        //     )}
        // </select>
    );
};

export default DateRangeSelector;
