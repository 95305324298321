import React, {useContext, useEffect, useState} from 'react';
import {
    Collapse, Modal, Box, Typography, Button,
    List, ListItem,
    Alert,
} from '@mui/material';
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated'
import TextField from "@mui/material/TextField";
import {apiRequest} from "../utils/api";
import {AuthRequest, AuthResponse} from "shared";
import useSignIn from 'react-auth-kit/hooks/useSignIn';

const AuthModal = () => {
    const [open, setOpen] = useState(false);
    const isAuthenticated = useIsAuthenticated();

    console.log("isAuthenticated", isAuthenticated);

    useEffect(() => {
        setOpen(!isAuthenticated);
    }, [isAuthenticated]);


    const signIn = useSignIn();

    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState(false);
    const [jiraUrl, setJiraUrl] = useState("");
    const [jiraUrlError, setJiraUrlError] = useState(false);
    const [apiTokenError, setApiTokenError] = useState(false);
    const [apiToken, setApiToken] = useState("");

    const [errorMessage, setErrorMessage] = useState("");

    const handleJiraUrlChange = (e: any) => {
        setJiraUrl(e.target.value);
        setJiraUrlError(!e.target.validity.valid);
    };

    const handleEmailChange = (e: any) => {
        setEmail(e.target.value);
        setEmailError(!e.target.validity.valid);
    };

    const handleApiTokenChange = (e: any) => {
        setApiToken(e.target.value);
        setApiTokenError(!e.target.validity.valid);
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        if (!e.target.checkValidity()) {
            if (!email) setEmailError(true);
            if (!jiraUrl) setJiraUrlError(true);
            if (!apiToken) setApiTokenError(true);
        } else {
            const authRequest: AuthRequest = {email: email, jiraUrl: jiraUrl, apiToken: apiToken};

            try {
                const response = await apiRequest<AuthResponse>('register', authRequest);
                if (response.error || !response.data) {
                    setErrorMessage(response.error || 'An unexpected error occurred. Please try again later.');
                    return;
                }
                console.log("GOT SOME DATA");
                console.log(response.data);
                const signedIn = signIn({
                    auth: {
                        token: response.data.token,
                        type: 'Bearer'
                    },
                    refresh: response.data.refreshToken,
                    userState: response.data.user
                });
                console.log("signed in: " + signedIn);
                setOpen(signedIn);
                if (signedIn) {
                    setErrorMessage("success");
                }
            } catch (error) {
                console.error("Error during registration request:", error);
                setErrorMessage('An unexpected error occurred. Please try again later.');
            }
        }
    };

    return (
        <Modal
            open={open}
            disableEscapeKeyDown
        >
            <Box component="form" onSubmit={handleSubmit} noValidate
                 sx={{
                     position: 'absolute',
                     top: '50%',
                     left: '50%',
                     transform: 'translate(-50%, -50%)',
                     width: 400,
                     bgcolor: 'background.paper',
                     border: '2px solid #000',
                     boxShadow: 24,
                     p: 4,
                 }}
            >
                <Typography variant="subtitle1" component="h2" gutterBottom
                            sx={{color: 'text.secondary', fontWeight: 'bold', marginBottom: '0.5rem'}}>
                    Connect to Jira Cloud
                </Typography>

                <List>
                    <ListItem><Typography><a href="https://id.atlassian.com/manage-profile/security/api-tokens"
                                             target="_blank"
                                             className="inline-block hover:text-primary mb-3 underline">Visit your
                        Atlassian's account</a> and
                        create an API token with any label.</Typography></ListItem>
                    <ListItem><Typography>Copy-paste it in the form below alongside your Jira e-mail and
                        url.</Typography></ListItem>

                </List>

                <TextField
                    required
                    label="Email"
                    value={email}
                    onChange={handleEmailChange}
                    error={emailError}
                    helperText={emailError ? "Please enter a valid email" : ""}
                    inputProps={{
                        type: "email",
                    }}
                    size="small"
                    sx={{margin: '0.5rem 0', width: '100%'}}
                />
                <TextField
                    required
                    label="Jira Url"
                    value={jiraUrl}
                    onChange={handleJiraUrlChange}
                    error={jiraUrlError}
                    inputProps={{
                        type: "url"
                    }}
                    helperText={
                        jiraUrlError ? "Please enter Jira Url" : ""
                    }
                    size="small"
                    sx={{margin: '0.5rem 0', width: '100%'}}
                />
                <TextField
                    required
                    label="API Token"
                    value={apiToken}
                    onChange={handleApiTokenChange}
                    error={apiTokenError}
                    inputProps={{}}
                    helperText={
                        apiTokenError ? "Please enter API Token" : ""
                    }
                    size="small"
                    sx={{margin: '0.5rem 0', width: '100%'}}
                />
                {errorMessage && errorMessage !== "success" && (
                    <Alert variant="outlined" severity="error" sx={{margin: '0.5rem 0'}}>{errorMessage}</Alert>
                )}
                {errorMessage && errorMessage === "success" && (
                    <Alert variant="outlined" severity="success" sx={{margin: '0.5rem 0'}}>Jira is connected, refresh
                        the page to continue</Alert>
                )}
                <Button variant="contained" color="primary" type="submit" sx={{marginTop: '1rem', width: '100%'}}>
                    Submit
                </Button>
            </Box>
        </Modal>
    );
};

export default AuthModal;
