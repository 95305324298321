import TopProjectsWidget from "../components/TopProjectsWidget";
import TopItemsWidget from "../components/TopItemsWidget";
import DistributionWidget from "../components/DistributionWidget";
import React from "react";
import TeamWidget from "../components/TeamWidget";
import {DashboardMeta, DistributionField, WidgetConfig} from "shared";


export function buildWidget(config: WidgetConfig, meta: DashboardMeta, index: number): JSX.Element {
    switch (config.type) {
        case 'TopProjectsWidget':
            return (<TopProjectsWidget key={index}/>);

        case 'TopItemsWidget':
            return (<TopItemsWidget scope={config.initialScope} initialLevel={Number(config.initialLevel)} key={index}/>);

        case 'TeamWidget':
            return (<TeamWidget scope={config.initialScope} key={index}/>);

        case 'DistributionWidget':
            const basicFields: DistributionField[] = meta.fieldOptions.flatMap((selectOption) => (

                meta.levelOptions.map((levelOption, level) => {
                    let distributionFieldName = selectOption.name;
                    if (level > 0) {
                        distributionFieldName += " [" + levelOption.name + " sum up]"
                    }

                    return ({
                        id: selectOption.id,
                        name: distributionFieldName,
                        level: level,
                        recommended: false
                    })
                })
            ));
            // const basicFields: DistributionField[] = meta.fieldOptions.map((selectOption) => ({
            //     id: selectOption.id,
            //     name: selectOption.name,
            //     level: 0,
            //     recommended: false
            // }));
            const allFields: DistributionField[] = [
                ...meta.distributionFields,
                ...basicFields
            ];
            return (
                <div style={{gridColumn: 'span 2'}} key={index}>
                    <DistributionWidget
                        initialScope={config.initialScope}
                        initialField={config.initialField}
                        initialChartType={config.initialChartType}
                        initialRangeType={config.initialRangeType}
                        initialRange={config.initialRange}
                        projects={meta.scopeOptions}
                        levels={meta.levelOptions}
                        fields={meta.distributionFields}
                        allFields={allFields}
                    />
                </div>
            );

        default:
            throw new Error(`Unhandled widget type: ${config}`);
    }
}