// pages/TeamsPage.js

import React, { useEffect, useState } from 'react';
import {apiRequest} from "../utils/api";

function TeamsDashboardPage() {
    const [data, setData] = useState(null);

    useEffect(() => {
        apiRequest('getTeamsData').then((response) => {setData(response.data)});
    }, []);

    return (
        <div>
            {data ? (
                <div>
                    <h1>Teams</h1>
                    {/* Add components for teams data here */}
                </div>
            ) : (
                'Loading...'
            )}
        </div>
    );
}

export default TeamsDashboardPage;
