import React from 'react';
import {useLocation} from 'react-router-dom';
import {AppBar, Box, Breadcrumbs, IconButton, Toolbar, Typography} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {useAppContext} from '../contexts/AppContext';
import Link from './Link';
import {useCurrentPage} from "../hooks/useCurrentPage";
// import { useAuth0 } from "@auth0/auth0-react";
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated'
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';

import useSignOut from 'react-auth-kit/hooks/useSignOut';
import {AuthUser} from "shared";

interface NavigationBarProps {
    toggleDrawer: (open: boolean) => void;
}

const NavigationBar: React.FC<NavigationBarProps> = ({toggleDrawer}) => {
    const {page, projectId} = useCurrentPage();
    const {dashboardMeta} = useAppContext();  // Assuming `projectName` comes from context or state

    let breadcrumbs;
    if (page === 'main-dashboard') {
        breadcrumbs = [
            <Typography sx={{color: 'white', fontSize: '1.2rem', fontWeight: 'bold'}} key={'main_dashboard'}>Main
                Dashboard</Typography>
        ];
    } else if (page === 'project') {
        breadcrumbs = [
            <Link to="/" style={{color: 'white', textDecoration: 'none', fontSize: '1.2rem'}} key={'main_dashboard'}>
                Main Dashboard
            </Link>,
            <Typography sx={{color: 'white', fontSize: '1.2rem', fontWeight: 'bold'}} key={'project'}>
                {projectId && dashboardMeta?.projects[projectId]?.name || `Loading...`}
            </Typography>
        ];
    } else {
        breadcrumbs = [
            <Link to="/" style={{color: 'white', textDecoration: 'none', fontSize: '1.2rem'}}>
                Main Dashboard
            </Link>,
            <Typography sx={{color: 'white', fontSize: '1.2rem', fontWeight: 'bold'}}>Other Page</Typography>
        ];
    }

    const user = useAuthUser<AuthUser>();

    const cleanUrl = (url: string) => {
        return url
            .replace(/^https?:\/\//, '') // Remove the protocol
            .replace(/\/$/, ''); // Remove trailing slash
    };

    return (
        <AppBar position="static">
            <Toolbar variant="dense" sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton edge="start" color="inherit" aria-label="menu" sx={{mr: 2}}
                            onClick={() => toggleDrawer(true)}>
                    <MenuIcon/>
                </IconButton>
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" sx={{color: 'white'}}/>}
                    aria-label="breadcrumb"
                    sx={{'& ol li': {marginTop: 0}}}
                >
                    {breadcrumbs}
                </Breadcrumbs>
                {/* todo: 1. display url on the left 2. remove http(s):// prefix and any / postfix if it's presented */}
                {user && (
                    <Box sx={{ marginLeft: 'auto', display: 'flex' }}>
                        {cleanUrl(user.jiraUrl)}
                    </Box>
                )}
            </Toolbar>
        </AppBar>
    );
};

import useSignIn from 'react-auth-kit/hooks/useSignIn';

const LoginButton = () => {
    const signIn = useSignIn();


    // signIn({
    //             auth: {
    //                 token: 'ey....mA',
    //                 type: 'Bearer'
    //             },
    //             refresh: 'ey....mA'
    //             userState: {
    //                 name: 'React User',
    //                 uid: 123456
    //             }
    //         })
    // return <button onClick={() => loginWithRedirect()}>Log In</button>;
};


const LogoutButton = () => {
    const signOut = useSignOut()

    return (
        <button onClick={() => signOut()}>Sign Out</button>
    )
};


export default NavigationBar;
